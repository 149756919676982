import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule,routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScanqrComponent } from './pages/scanqr/scanqr.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NbThemeModule } from '@nebular/theme';
import { DeviceDetailsComponent } from './pages/device/device-details/device-details.component';
import { AddDeviceDataComponent } from './pages/device/add-device-data/add-device-data.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializer } from './Keycloak-init';
import { AuthInterceptorProvider } from './utils/interceptors/auth.interceptor';
import { NgToastModule } from 'ng-angular-popup';
import { FinishDeviceDataComponent } from './pages/device/finish-device-data/finish-device-data.component';






@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    ScanqrComponent,
    DeviceDetailsComponent,
    AddDeviceDataComponent,
    FinishDeviceDataComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ZXingScannerModule,
    FormsModule,
    FontAwesomeModule,
    HttpClientModule,
    NbThemeModule.forRoot(),
    ReactiveFormsModule,
    KeycloakAngularModule,
    NgToastModule,

  ],
  providers: [
    AuthInterceptorProvider,
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],
    },

],
  bootstrap: [AppComponent]
})
export class AppModule { }
