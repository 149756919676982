<div class="container">
  <div class="card">
    <h4 class="card-header" style="background-color:#007bff; color: white">Device Attributes</h4>
    <div class="card-body">
      <form action="" class="">
        <div *ngIf="!this.url" class="row mb-3">
          <div class="text-center">
            <img role='button' src="assets/images/image-upload-placeholder.jpg"
                 class="img-fluid mx-auto d-block rounded" style="max-height: 15rem;"
                 (click)="this.updateImageFileSelect()" alt="device_picture"/>
          </div>
        </div>
        <div *ngIf="this.url" class="row mb-3">
          <div class="text-center">
            <img [src]="url" class="img-fluid mx-auto d-block rounded" style="max-height:16rem;"/>
          </div>
        </div>
        <div class="mb-3 row">
          <div class="text-center">
            <!--                        <input class="form-control" name="sensorImage" type="file" (change)="onFileSelected($event)">-->
            <button class="btn btn-primary" (click)="this.updateImageFileSelect()">Upload image</button>
          </div>
        </div>
        <input class="form-control d-none" id='imageFileSelect' name="sensorImage" type="file" accept="image/*"
               capture="environment" (change)="onFileSelected($event)"/>
        <div class="mb-3 row">
          <label for="sensorName" class="col-sm-4 col-form-label">Device Name</label>
          <div class="col-sm-8">
            <input type="sensorName" name="sensorName" class="form-control"
                   [(ngModel)]="deviceMetadata.sensorName" id="sensorName">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="plant" class="col-sm-4 col-form-label">Plant</label>
          <div class="col-sm-8">
            <input name="plant" class="form-control" [(ngModel)]="deviceMetadata.plant" id="plant" aria-label="plant">
            <!--<select class="form-select" id="plant" aria-label="plant" [(ngModel)]="deviceMetadata.plant"
                    name="plant">
              <option value="AWerk Paderborn"> Werk Paderborn</option>
              <option value="Werk München"> Werk München</option>
              <option value="Werk Krakau">Werk Krakau</option>
            </select>-->
          </div>
        </div>
        <div class="mb-3 row">
          <label for="application" class="col-sm-4 col-form-label">Application</label>
          <div class="col-sm-8">
            <input name="application" class="form-control" [(ngModel)]="deviceMetadata.application" id="application"
                   aria-label="application">
            <!--<select class="form-select" id="application" aria-label="application"
                    [(ngModel)]="deviceMetadata.application"
                    name="application">

              <option value="Regal">Regal</option>

              <option value="Etagenförderer">Etagenförderer</option>
              <option value="Förderbahn">Förderbahn</option>
            </select>-->
          </div>
        </div>

        <div class="mb-3 row">
          <label for="localization" class="col-sm-4 col-form-label">Localization</label>
          <div class="col-sm-8">
            <input name="localization" class="form-control" [(ngModel)]="deviceMetadata.localization" id="localization"
                   aria-label="localization">
            <!--<select class="form-select" id="localization" aria-label="localization"
                    [(ngModel)]="deviceMetadata.localization"
                    name="localization">

              <option value="Block 1">Block 1</option>
              <option value="Block 2">Block 2</option>
              <option value="Block 3">Block 3</option>

            </select>-->
          </div>
        </div>

        <div class="mb-3 row">
          <label for="building" class="col-sm-4 col-form-label">Building</label>
          <div class="col-sm-8">
            <input name="building" class="form-control" [(ngModel)]="deviceMetadata.building" id="building"
                   aria-label="building">
            <!--<select class="form-select" id="building" aria-label="building" [(ngModel)]="deviceMetadata.building"
                    name="building">
              <option value="Halle A">Halle A</option>
              <option value="Halle B">Halle B</option>
              <option value="Hochregallager">Hochregallager</option>
            </select>-->
          </div>
        </div>

        <br>
        <div class="mb-3 row">
          <div style="text-align: center;">
            <button type="submit" class="btn btn-primary" (click)="submit()">Save</button>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
