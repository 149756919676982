import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgToastService} from 'ng-angular-popup';
import {DevicesService} from 'src/app/utils/devices.service';
import {DeviceMetadata} from '../device-details/deviceMetadata';

@Component({
  selector: 'app-add-device-data',
  templateUrl: './add-device-data.component.html',
  styleUrls: ['./add-device-data.component.scss']
})
export class AddDeviceDataComponent implements OnInit {

  selectedFile: File;
  deviceMetadata: DeviceMetadata = {
    sensorName: '',
    plant: '',
    application: '',
    localization: '',
    building: ''
  };


  url = "";
  serialNumber: string
  form: FormGroup;
  addDeviceDataForm: FormGroup
  imageSrc: string;

  constructor(private router: Router,
              private deviceService: DevicesService,
              private activatedRoute: ActivatedRoute,
              private toast: NgToastService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute) {
    this.form = this.formBuilder.group({
      serialNumber: [''],
      sensorImage: [null]
    })
  }

  ngOnInit(): void {
    let deviceSerial = this.route.snapshot.paramMap.get('serialNumber');
    if (deviceSerial != null) {
      this.getDeviceAttributes(deviceSerial)
    }
  }

  getDeviceAttributes(deviceSerial: string) {
    this.deviceService.getDeviceAttributes(deviceSerial).subscribe({
      next: (deviceAttributes) => {
        if (deviceAttributes != null) {
          this.deviceMetadata = deviceAttributes
          if (deviceAttributes.sensorImage)
            this.url = "data:image/png;base64," + deviceAttributes.sensorImage
        }
      },
      error: (err) => {
        this.toast.error({summary: "Failed to get device attributes.", detail: "ERROR", duration: 5000})
      }
    });
  }

  onFileSelected(event: any) {
    if (event.target.files) {
      this.selectedFile = event.target.files[0];
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (event: any) => {
        this.url = event.target.result
      }
    }

  }

  submit() {
    const data = {
      sensorName: this.deviceMetadata.sensorName,
      plant: this.deviceMetadata.plant,
      application: this.deviceMetadata.application,
      localization: this.deviceMetadata.localization,
      building: this.deviceMetadata.building,

      serialNumber: this.serialNumber = this.activatedRoute.snapshot.params['serialNumber'],

    };
    // write data
    this.deviceService.saveData(data).subscribe({
      next: () => {
        if (this.url != "") {
          this.deviceService.uploadFile(this.selectedFile, data.serialNumber).subscribe({
            error: (err) => {
              this.toast.error({
                detail: "Image was not saved, Please try again later.",
                summary: err.statusText,
                duration: 5000
              })
            },
          });
        }

      },
      error: (err) => {
        this.toast.error({detail: "Data was not saved, Please try again.", summary: err.statusText, duration: 5000})
      },
      complete: () => {
        this.toast.success({summary: "Device attributes were saved successfully.", detail: "Success", duration: 5000})
        this.router.navigate(['']);
      }
    });
  }

  showSuccessDialog() {
    this.router.navigate(['addDeviceData/' + this.serialNumber + '/succeeded'],);
  }


  saveData(data: any) {
    return this.deviceService.saveData(data).subscribe(res => {
    })
  }

  updateImageFileSelect() {
    document.getElementById('imageFileSelect')!!.click()
  }
}
