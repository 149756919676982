import {KeycloakEvent, KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment';

export function initializer(keycloak: KeycloakService) {
  keycloak.keycloakEvents$.subscribe(async (event: KeycloakEvent) => {
    if (event.type === KeycloakEventType.OnTokenExpired) {
      if (keycloak.getKeycloakInstance().refreshToken) {
        keycloak.updateToken(40);
      } else {
       keycloak.logout();
      }
    }
  });
  return () =>
  // TODO: introduce multitenancy realm switch here.
    keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId,
      },
      initOptions: {
        onLoad: 'login-required',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        responseMode: 'fragment',
        flow: 'standard'
      },
    });
}
