<div class="container">

  <div class="col-md-12 text-center" *ngIf="this.deviceError > 404">
    <br>
    <h5 class="text-center">There was an error fetching device data</h5>
  </div>

  <div class="col-md-12 text-center" *ngIf="this.deviceError == 404">
    <br>
    <h5 class="text-center">No device registered with the specified Serial Number</h5>
  </div>
  <div class="devicedetails" *ngIf="this.deviceError < 400 && this.deviceConfig">

    <div class="d-grid gap-2">
      <button class="btn btn-success" type="button"
              name="addDeviceDetails"
              (click)="addDeviceData(deviceConfig.serialNumber)">
        <i class="fa fa-plus" aria-hidden="true"></i>
        Add Attributes
      </button>

      <button class="btn btn-warning" type="button"
              name="replaceDevice"
              (click)="replaceDevice()">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        Replace Device
      </button>
    </div>
    <br>

    <!--    <div class="row">-->
    <!--      <div class="text-center">-->
    <!--        <img src="{{'data:image/jpg;base64,' + deviceConfig.sensorImage}}" class="img-fluid mx-auto d-block rounded mb-3" alt="device_picture"/>-->
    <!--        <h5>{{deviceConfig.productName}}</h5>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <br>-->

    <div class="row">

      <div class="col-lg-6 mb-3">
        <div class="card h-100">
          <h5 class="card-header" style="background-color:#007bff; color: white">Device Details</h5>
          <div class="card-body">
            <div class="mb-3 row">
              <div class="col">Serial number:</div>
              <div class="col">{{ deviceConfig.serialNumber }}</div>
            </div>
            <div class="mb-3 row">
              <div class="col">Model:</div>
              <div class="col">{{ deviceConfig.devModel }}</div>
            </div>
            <!--<div class="mb-3 row">
              <div class="col">Firmware Version:</div>
              <div class="col">{{ deviceConfig.firmwareVersion }}</div>
            </div>
            <div class="mb-3 row">
              <div class="col">Hardware Version:</div>
              <div class="col">{{ deviceConfig.hardwareVersion }}</div>
            </div>
            <div class="mb-3 row">
              <div class="col">Production Date:</div>
              <div class="col">{{ deviceConfig.productionDate }}</div>
            </div>
            <div class="col"></div>-->
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-3">
        <div class="card h-100">
          <h5 class="card-header" style="background-color:#007bff; color: white">Contact</h5>
          <div class="card-body">
            <div class="mb-3 row">
              <div class="col">Support Hotline:</div>
              <div class="col">{{ deviceConfig.supportHotline }}</div>
            </div>
            <div class="mb-3 row">
              <div class="col">Support E-Mail:</div>
              <div class="col">{{ deviceConfig.supportEmail }}</div>
            </div>

            <!-- <div class="mb-3 row">
               <div class="col">Product Link:</div>
               <div class="col"><a href="{{deviceConfig.productLink}}">{{ deviceConfig.productLink }}</a></div>
             </div>-->
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-3">
        <div class="card h-100">
          <h5 class="card-header" style="background-color:#007bff; color: white">Device Information</h5>
          <div class="card-body">
            <div class="mb-3 row">
              <div class="col">Device:</div>
              <div class="col">{{ deviceConfig.productName }}</div>
            </div>
            <div class="mb-3 row">
              <div class="col">CPU:</div>
              <div class="col">{{ deviceConfig.productText }}</div>
            </div>

            <div class="mb-3 row">
              <div class="col">TPM:</div>
              <div class="col">{{ deviceConfig.productLink }}</div>
            </div>

            <div class="mb-3 row">
              <div class="col">OS:</div>
              <div class="col">{{ deviceConfig.productId }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-3">
        <div class="card h-100">
          <h5 class="card-header" style="background-color:#007bff; color: white">Vendor</h5>
          <div class="card-body">
            <div class="mb-3 row">
              <div class="col">Vendor Name:</div>
              <div class="col">{{ deviceConfig.vendorName }}</div>
            </div>
            <div class="mb-3 row">
              <div class="col">Support E-Mail:</div>
              <div class="col">{{ deviceConfig.vendorText }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
