import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private keycloakService: KeycloakService) {
  }
  getLoggedUser() {
    return this.keycloakService.getKeycloakInstance().loadUserInfo().then(function(data: { [x: string]: any; }) {
      return data['name'];
    }).catch(function(data) {
      return undefined;
    });
  }
  getUserToken() {
    try {
      return this.keycloakService.getKeycloakInstance().token;
    } catch (e) {
      return undefined;
    }
  }
  getAuthorizationHeader() {
    return new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + this.getUserToken(),
    });
  }
  logout() {
    this.keycloakService.logout().then();
  }
  redirectToProfile() {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }
  getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }
}
