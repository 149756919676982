import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  backgroundImage:string= "assets/images/Background.jpg"
  constructor() { }

  ngOnInit(): void {
  }

  value:any = Math.random();

  

}
