import {transformAll} from '@angular/compiler/src/render3/r3_ast';
import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {DevicesService} from 'src/app/utils/devices.service';
import {DeviceConfig} from '../device';


@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss']
})
export class DeviceDetailsComponent implements OnInit {
  deviceSerial: String | null;
  // http response status
  deviceError: Number = 0;

  //retrievedImage: './';
  base64Data: any;

  defaultSupportHotline: String = "+49 5251 600 4424"
  defaultSupportEmail: String = "info@devity.eu"
  defaultProductLink: String = "https://devity.eu"

  imageFile = 'assets/images/sensor.jpg';
  deviceConfig: DeviceConfig = {
    serialNumber: "",
    productName: "",
    productText: "",
    devModel: "",
    vendorName: "",
    vendorText: "",
    productId: "",
    firmwareVersion: "",
    hardwareVersion: "",
    productionDate: "",
    sensorImage: "",
    supportHotline: "",
    supportEmail: "",
    productLink: ""
  };

  constructor(public router: Router, private deviceService: DevicesService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    //this.route.params.subscribe( params => this.deviceSN = params['deviceSN']);
  }


  ngOnInit(): void {

    this.deviceSerial = this.route.snapshot.paramMap.get('serialNumber');
    console.log(this.deviceSerial)
    if (this.deviceSerial != null) {
      this.getDevice(this.deviceSerial)
    }
  }

  getDevice(serial: String) {
    this.deviceService.getDevice(serial).subscribe({
      next: deviceConfig => {
        this.deviceConfig = deviceConfig;
      },
      error: (err) => {
        this.deviceError = err.status;
      },
      complete: () => {
        this.deviceError = 200;
      },
    });
  }

  transform() {
    // this.sanitizer.bypassSecurityTrustUrl(this.retrievedImage)
  }

  addDeviceData(serialNumber: String) {
    console.log(serialNumber);

    this.router.navigate(['addDeviceData/' + serialNumber]);
  }

  replaceDevice() {
    console.log(this.deviceSerial);

    this.router.navigate(['scanqr/' + this.deviceSerial!!]);
  }

}
