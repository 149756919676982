import {HttpClient, HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, Observable, of, retry, tap} from 'rxjs';
import {environment} from 'src/environments/environment';
import {DeviceConfig} from '../pages/device/device';
import {AuthService} from './auth.service';
import {DeviceMetadata} from "../pages/device/device-details/deviceMetadata";

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  private devicesUrl = environment.deviceApiUrl;

  constructor(private http: HttpClient, private authToken: AuthService) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken.getUserToken()
    }),
  };

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  getDevice(serialNumber: String): Observable<DeviceConfig> {

    return this.http.get<DeviceConfig>(this.devicesUrl + "devicesConfig/" + serialNumber, this.httpOptions);
  }


  getDeviceAttributes(serialNumber: string): Observable<any> {
    return this.http.get<DeviceMetadata>(this.devicesUrl + "deviceMetadata/attributes/" + serialNumber, this.httpOptions);
  }

  saveData(data: any): Observable<any> {
    console.log(data)
    return this.http.post<DeviceConfig>(this.devicesUrl + "deviceMetadata/saveMetadata", data, this.httpOptions);
  }


  uploadFile(sensorImage: File, serialNumber: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('sensorImage', sensorImage, sensorImage.name)
    return this.http.post<any>(this.devicesUrl + "deviceMetadata/upload/" + serialNumber, formData, this.httpOptions);
  }

  replaceDevice(oldDeviceSerial: String, newDeviceSerial: String): Observable<any> {
    return this.http.put<any>(this.devicesUrl + "devices/" + oldDeviceSerial + "/replace/" + newDeviceSerial, this.httpOptions);
  }

  private log(s: string) {
  }
}
