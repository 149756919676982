<div class="container">
  <div class="card">
    <h4 class="card-header" style="background-color:#007bff; color: white">{{devices[whichDevice]}}</h4>
    <div class="card-body">
      <div *ngIf="!manualEntry" class="scanner-shell" [hidden]="!hasDevices">


        <!--     <select *ngIf="qrResultString" class="form-control"  [(ngModel)]="deviceSelected" (click)="onDeviceSelectChange(deviceSelected)">-->
        <!--          <option value="">No Device Selected</option>-->
        <!--          <option *ngFor="let device of availableDevices" [value]="device.deviceId">{{device.label}}</option>-->
        <!--     </select>-->


        <zxing-scanner *ngIf="!qrResultString" [torch]="torchEnabled" [device]="deviceCurrent"
                       (deviceChange)="onDeviceChange($event)" (scanSuccess)="onCodeResult($event)"
                       [formats]="formatsEnabled"
                       [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
                       (camerasFound)="onCamerasFound($event)"
                       (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>


        <!-- <section class="results"  >;<app-device-details></app-device-details>

    <button mat-icon-button (click)="clearResult()">
      &times;
    </button>

    </section> -->
      </div>
      <div *ngIf="manualEntry" class="d-grid justify-content-center">

        <div class="mb-3 row">
          <label for="sensorName" class="col-sm-4 col-form-label">Serial Number</label>
          <div class="col-sm-8">
            <input type="text" name="serialNumber" class="form-control"
                   [(ngModel)]="qrResultString" id="sensorName">
          </div>
        </div>
        <button class="btn btn-outline-secondary" name="submit"
                (click)="submit()"> Submit
        </button>


      </div>


      <div *ngIf="!manualEntry" class="d-grid justify-content-center">
        <button class="btn btn-outline-secondary" name="submit"
                (click)="enterManually()"> Enter Manually
        </button>
      </div>

    </div>
  </div>
</div>
