<div class="px-4 py-5 my-5 text-center">
  <img class="d-block mx-auto mb-4" src="assets/images/succeeded_check.png" alt="" width="72" height="72">
  <h1 class="display-5 fw-bold">Attribute erfolgreich gespeichert</h1>
  <div class="col-lg-6 mx-auto">
    <p class="lead mb-4">
      Geräteinformationen wurden erfolgreich gespeichert. Das Gerät kann nun eingeschaltet werden.
      <b>Während des Startvorgangs wird das Gerät '{{this.serialNumber}}' provisioniert.</b></p>
    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
      <button type="button" class="btn btn-primary btn-lg px-4 gap-3" (click)="dismiss()">weiteres Gerät erfassen</button>
      <!--      <button type="button" class="btn btn-outline-secondary btn-lg px-4">Secondary</button>-->
    </div>
  </div>
</div>
