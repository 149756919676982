import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HTTP_INTERCEPTORS, HttpHeaders, HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { KeycloakService } from 'keycloak-angular';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.testing';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authToken: AuthService, private keycloak: KeycloakService) {

  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    /*
    401 Unauthorized:
      OCS may return 401 when an expired token being used,
      or when the request contains no token at all
    403 Forbidden:
      Keycloak's token endpoint may return 403 in case the
      authenticated user doesn't have enough permission to be granted a token.
      This can happen because Keycloak needs to match the user and his/her
      permissions/scopes to the resources being requested.
      It then decides if the user should be granted a token or not.

    In both cases, it doesn't make much sense for users to stay logged-in,
    since they can no longer access the resources/services.
    */
    if (err.status === 401) {
      this.keycloak.logout().then();
      return of(err.message);
    } else if (err.url && err.url.startsWith(environment.keycloak.url) && err.status === 403) {
      this.keycloak.logout().then();
      return of(err.message);
    }

    return throwError(err);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = this.authToken.getAuthorizationHeader();
    request = request.clone({
      headers,
    });
    return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
  }
}

export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
