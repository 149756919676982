import {Component, OnInit, ViewChild} from '@angular/core';
import {BarcodeFormat} from '@zxing/library';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {BehaviorSubject} from 'rxjs';

import {ActivatedRoute, Router} from '@angular/router';
import {DevicesService} from "../../utils/devices.service";
import {NgToastService} from "ng-angular-popup";

@Component({
  selector: 'app-scanqr',
  templateUrl: './scanqr.component.html',
  styleUrls: ['./scanqr.component.scss']
})
export class ScanqrComponent implements OnInit {

  @ViewChild(ZXingScannerComponent) scanner: ZXingScannerComponent;
  availableDevices: MediaDeviceInfo[];
  deviceCurrent: any;
  deviceSelected: string;
  qrResultString: string;

  manualEntry: Boolean = false
  devices = ["Current Device", "Replacement Device"]
  whichDevice = 0

  currentDeviceSerialNumber: string | null;

  public ngOnInit(): void {
    this.clearResult();
    this.deviceSelected = "";
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.currentDeviceSerialNumber = this.route.snapshot.paramMap.get('serialNumber');

    console.log(this.currentDeviceSerialNumber)
    if (this.currentDeviceSerialNumber == null) {
      this.whichDevice = 0
    } else {
      this.whichDevice = 1
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DevicesService,
    private toast: NgToastService,
  ) {
  }

  ngOnChanges() {

  }


  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.CODE_39,
    BarcodeFormat.DATA_MATRIX,
    // BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];


  hasDevices: boolean;
  hasPermission: boolean;


  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;


  clearResult(): void {
    this.whichDevice = 0
    this.qrResultString = "";
    this.currentDeviceSerialNumber = null
  }


  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.scanner.enable = false;
    this.submitQrCodeValue(resultString)
    this.clearResult()
  }


  onDeviceSelectChange(selected: any) {

    const selectedStr = selected || '';
    if (selectedStr === "") {
      this.scanner.enable = false;
    } else {
      this.scanner.enable = true;
    }
    if (this.deviceSelected === selectedStr) {
      return;
    }

    this.deviceSelected = selectedStr;
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.deviceCurrent = device || undefined;
  }


  submitQrCodeValue(qrResultString: string) {
    if (this.whichDevice == 0) {
      this.getDevice(qrResultString);
    }
    else
      this.replaceDevice(qrResultString);
  }

  getDevice(qrResultString: string) {
    this.router.navigate(['devicesConfig/' + qrResultString]);
  }

  replaceDevice(newDeviceSerial: String) {
    console.log(newDeviceSerial);
    console.log(this.currentDeviceSerialNumber);
    this.deviceService.replaceDevice(this.currentDeviceSerialNumber!!, newDeviceSerial).subscribe({
      next: any => {
        this.toast.success({summary: "Device has been replaced successfully.",detail:"Success", duration: 5000})
        this.router.navigate(['']);
      },
      error: (err) => {
        this.toast.error({summary: "Device replacement failed, please try again.", detail: "ERROR", duration: 5000})
        this.router.navigate(['']);
      }
    });
  }


  onDeviceChange(device: MediaDeviceInfo) {
    const selectedStr = device?.deviceId || '';

    if (this.deviceSelected === selectedStr) {
      return;
    }

    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }


  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  onFileSelected(event: any) {
  }

  enterManually(): void {
    this.manualEntry = true;
  }

  submit(): void {
    this.submitQrCodeValue(this.qrResultString)
  }
}
