

<nav class="navbar navbar-expand-sm navbar-custom py-4">
  <div class="container">

      <a class="" href="#">
        <img class="logo-img" src="assets/images/logo.png" alt="DEVITY"/>
      </a>


    <div class="d-grid gap-2 col-6 d-md-flex justify-content-md-end">
      <a href="/scanqr" class="btn btn-outline-secondary" role="button" type="button">New Entry</a>
    </div>

  </div>
</nav>
