import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-finish-device-data',
  templateUrl: './finish-device-data.component.html',
  styleUrls: ['./finish-device-data.component.scss']
})
export class FinishDeviceDataComponent implements OnInit {

  @Input() title: string;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) {}

  serialNumber: String;

  ngOnInit(): void {
    this.serialNumber = this.activatedRoute.snapshot.params['serialNumber']
  }

  dismiss() {
    this.router.navigate(['scanqr'])
  }

}
