import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { AddDeviceDataComponent } from './pages/device/add-device-data/add-device-data.component';
import { DeviceDetailsComponent } from './pages/device/device-details/device-details.component';
import { ScanqrComponent } from './pages/scanqr/scanqr.component';
import { FinishDeviceDataComponent } from "./pages/device/finish-device-data/finish-device-data.component";



const routes: Routes = [
  {path: '', redirectTo: 'scanqr', pathMatch: 'full'},
  // { path: '', canActivate: [AuthGuard],component: DashboardComponent, pathMatch: 'full'},
  {path: 'scanqr', canActivate: [AuthGuard], component: ScanqrComponent},
  {path: 'scanqr/:serialNumber', canActivate: [AuthGuard], component: ScanqrComponent,},
  {path: 'addDeviceData/:serialNumber', component: AddDeviceDataComponent},
  {path: 'devicesConfig/:serialNumber', component: DeviceDetailsComponent},
  {path: 'addDeviceData/:serialNumber/succeeded', component: FinishDeviceDataComponent},
  //     { path: 'addDeviceData/:serialNumber', component: AddDeviceDataComponent },
  // {  path: 'devicesConfig/:serialNumber', component: DeviceDetailsComponent }

]


@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [ DashboardComponent]
